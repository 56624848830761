.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.active-cell {
  color: green;
}

.inactive-cell {
  color: red;
}

.revision-cell {
  color: orange;
}

.cell-green {
  color: green;
}

.cell-red {
  color: red;
}

.cell-orange {
  color: orange;
}

.cell-yellow {
  color: blue;
}



@media screen and (min-width: 601px) and (max-width: 1500px){
  .grid-heigth {
    height: 400px;
  }
}

@media screen and (min-width: 1501px){
  .grid-heigth {
    height: 750px;
  }
}

/* @media only screen and (width <= 1400px){
  .grid-heigth {
    height: 400px;
  }
}

@media only screen and (width > 1400px){
  .grid-heigth {
    height: 750px;
  }
} */
